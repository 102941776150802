import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { motion } from 'framer-motion';
import { getMeta } from '../../../../helpers/getMeta';
import {
  backgroundContainer,
  logo,
  logosContainer,
  textsContainer,
} from './styles';
import { createScene } from '../../../../helpers/createScene';
import { FONTS } from '../../../../enums/fonts';

interface IBContent extends IBaseMeta {
  textLines?: Texts;
  responsibleCircleImage?: string;
  responsibleLogoImages?: string[];
}

const default_delay = 0.5;

const ResponsibleHTML = (props: IStateProps) => {
  const meta = getMeta(props);
  return <ResponsibleContainer {...meta} />;
};

export const ResponsibleScene = createScene(ResponsibleHTML, null);

const ResponsibleContainer = ({ ...meta }: IBContent) => {
  return (
    <div style={backgroundContainer}>
      <ResponsibleLogos {...meta} />
      <div style={textsContainer}>
        <ResponsibleTexts {...meta} />
      </div>
    </div>
  );
};

const ResponsibleLogos = ({ ...meta }: IBContent) => {
  return (
    <div style={logosContainer}>
      <motion.div
        style={logo}
        initial={{ rotateY: 0 }}
        animate={{ rotateY: 180 }}
        transition={{
          delay: default_delay + 0.25,
          duration: 0.5,
          type: 'spring',
          bounce: 0.1,
        }}
      >
        <img
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
          }}
          src={meta?.responsibleCircleImage}
          alt="responsible-circle"
        />
      </motion.div>
      <motion.div
        style={logo}
        animate={{ scale: [1, 0.85, 0.85, 1] }}
        transition={{
          delay: default_delay,
          duration: 1,
          times: [0, 0.25, 0.75, 1],
        }}
      >
        <motion.div
          style={logo}
          initial={{ rotateY: 0 }}
          animate={{
            rotateY: [0, 90, 270, 360],
            opacity: [1, 1, 0, 1],
          }}
          transition={{
            delay: default_delay + 1,
            duration: 0.5,
            times: [0, 0.5, 0.505, 1],
          }}
        >
          {meta?.responsibleLogoImages?.map((src, i) => (
            <img style={logo} key={i} src={src} alt={`responsible-logo-${i}`} />
          ))}
        </motion.div>
      </motion.div>
    </div>
  );
};

const ResponsibleTexts = ({ ...meta }: IBContent) => {
  return (
    <>
      {meta?.textLines?.map(({ color, size, text }, i) => (
        <motion.div
          key={i}
          initial={{ opacity: 0, x: '100vw' }}
          animate={{ opacity: [0, 1], x: 0 }}
          transition={{
            delay: default_delay + 1.5 + 0.25 * i,
            duration: 0.75,
            ease: 'easeInOut',
            x: {
              delay: default_delay + 1.5 + 0.25 * i,
              duration: 1,
              type: 'spring',
              bounce: 0.25,
            },
          }}
        >
          <span
            style={{
              fontSize: size,
              fontFamily: FONTS.BarlowCondensedSemiBold,
              fontWeight: 'semibold',
              color: color,
            }}
          >
            {text}
          </span>
        </motion.div>
      ))}
    </>
  );
};
