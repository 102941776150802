import { IStateProps } from '../..';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { NextDraw } from '../../../Common/NextDraw';
import { BoostSplash } from '../../../Common/BoostSplash';
import { IBangoMeta, Texts } from '../../../../interfaces/sceneState';
import {
  formatDrawRelativeDate,
  formatPrizeNumber,
} from '../../../../helpers/formatters';
import { FONTS } from '../../../../enums/fonts';
import { COLORS } from '../../../../constants/colours';
import moment from 'moment/moment';
import { MetaText } from '../../../Common/MetaText';
import { gameLogo, JackpotContainer, JackpotSubContainer } from './styles';
import { MediaComponent } from '../../../Common/MediaComponent';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';
import euromillionsLogo from '../../../../assets/euromillionsLogo.svg';
import swissLotoLogo from '../../../../assets/swissLotoLogo.svg';
import ResultBanner from '../../../Common/ResultBanner';

const EUROMILLIONS = 'EUROMILLIONS';
const SWISS_LOTO = 'SWISS LOTO';

interface IBContent extends IBangoMeta {
  backgroundVideo?: string;
  backgroundImages?: string[];
  nextLotteryDrawTime?: string;
  amountMultiplierText?: Texts;
  jackpot?: number;
  decimalPlaces?: number;
  gameName?: string;
}

const LotteryHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IBContent;
  const JackpotValue = ({ ...meta }: IBContent) => {
    const showJackpot = moment(
      new Date(meta.nextLotteryDrawTime ?? ''),
    ).isAfter(moment());
    if (!showJackpot) return null;
    return (
      <MetaText
        content={{
          text: formatPrizeNumber(meta.jackpot / 1e8, meta.decimalPlaces),
          size: 162,
          color: COLORS.white,
          font: FONTS.BarlowCondensedBold,
        }}
      />
    );
  };
  const JackpotMultiplier = ({ ...meta }: IBContent) => {
    const showJackpot = moment(
      new Date(meta.nextLotteryDrawTime ?? ''),
    ).isAfter(moment());
    if (!showJackpot) return null;
    return (
      <>
        {meta.amountMultiplierText?.map((elem, index) => (
          <MetaText key={index} content={elem} />
        ))}
      </>
    );
  };
  const JackpotTime = ({ ...meta }: IBContent) => {
    return (
      <MetaText
        content={{
          text: formatDrawRelativeDate(
            props.language,
            new Date(meta.nextLotteryDrawTime ?? ''),
          )?.toUpperCase(),
          size: 32,
          color: COLORS.white,
          font: FONTS.BarlowCondensedSemiBold,
        }}
      />
    );
  };
  const JackpotContent = ({ ...meta }: IBContent) => {
    return (
      <div style={JackpotContainer}>
        <JackpotValue {...meta} />
        <div style={JackpotSubContainer}>
          <JackpotMultiplier {...meta} />
          <JackpotTime {...meta} />
        </div>
      </div>
    );
  };

  return (
    <>
      <Logo {...meta} />
      <JackpotContent {...meta} />
      <DrawInProgress scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <BoostSplash scene={props.displayState} {...props} />
      <MediaComponent
        backgroundImages={meta?.backgroundImages}
        backgroundVideo={meta?.backgroundVideo}
        {...props}
      />
      <ResultBanner
        ballValues={meta.balls ?? []}
        extraValue={meta.extra ?? 0}
        {...props}
      />
    </>
  );
};

const Logo = ({ ...meta }: IBContent): React.ReactElement => {
  const gameId = meta.gameName;

  const logoUrl = (() => {
    switch (gameId) {
      case EUROMILLIONS:
        return euromillionsLogo;
      case SWISS_LOTO:
        return swissLotoLogo;
    }
  })();

  return <img src={logoUrl} style={gameLogo} alt={`Game logo ${gameId}`} />;
};

export const LotteryScene = createScene(LotteryHTML, null);
