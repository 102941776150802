import React from 'react';
import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import boostWheelBoostText from '../../../../assets/boostWheelBoostText.svg';
import boostWheelCaret from '../../../../assets/boostWheelCaret.svg';
import { motion } from 'framer-motion';
import {
  boostImage,
  boostImageContainer,
  contentContainer,
  textContainer,
  wheel,
  wheelBoostLogo,
  wheelBoostNumber,
  wheelCaret,
  wheelCentralContainer,
  wheelContainer,
  wheelImage,
} from './styles';
import BoostWheelSvg from '../../../Common/BoostWheelSvg';
import { MetaText } from '../../../Common/MetaText';
import BoostBackground from '../../../Common/BoostBackground';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';

const default_delay = 1;
const spin_duration = 3.5;
const boost_logo_duration = 2.5;
const text_duration = 2.5;
const wheel_appear_duration = 1.5;
const wheel_boost_text_appear_duration = 0.5;
const wheel_boost_scale_center_duration = 1;

interface IBContent extends IBaseMeta {
  topText?: Texts;
  bottomText?: Texts;
  boostImage?: string;
  boost?: number;
}

const BoostHTML = (props: IStateProps): React.ReactElement => {
  const meta = getMeta(props);
  return (
    <>
      <BoostBackgroundContent {...meta} />
      <Contents {...meta} />
      <LotoExpressLogo {...props} />
    </>
  );
};
export const BoostScene = createScene(BoostHTML, null);

const BoostBackgroundContent = ({ boost }: IBaseMeta) => {
  return (
    <BoostBackground
      boost={boost}
      initial={{ clipPath: 'circle(0%)', rotate: 0, scale: 1 }}
      animate={{ clipPath: 'circle(100%)', rotate: 360 / 8, scale: 3 }}
      transition={{
        delay:
          default_delay +
          boost_logo_duration +
          text_duration +
          wheel_appear_duration +
          spin_duration +
          wheel_boost_text_appear_duration +
          1,
        duration: 2,
      }}
    />
  );
};

const Contents = ({ ...meta }: IBContent): React.ReactElement => {
  return (
    <div style={contentContainer}>
      <motion.div
        style={boostImageContainer}
        initial={{
          opacity: 0,
          scale: 0.001,
        }}
        animate={{
          opacity: [0, 1, 1, 1, 0],
          scale: [0.001, 1],
        }}
        transition={{
          opacity: {
            delay: default_delay,
            duration: boost_logo_duration,
            ease: 'easeInOut',
          },
          scale: {
            delay: default_delay,
            duration: boost_logo_duration,
            type: 'spring',
            bounce: 0.3,
          },
        }}
      >
        <BoostImage src={meta?.boostImage} />
      </motion.div>
      <motion.div
        style={textContainer}
        initial={{
          scale: 0,
          opacity: 0,
        }}
        animate={{
          scale: [1, 1, 1, 1, 1, 0],
          opacity: [0, 1, 1, 1, 1, 0],
        }}
        transition={{
          delay: default_delay + boost_logo_duration,
          duration: text_duration,
          ease: 'easeInOut',
        }}
      >
        <motion.div
          animate={{
            translateX: ['-150vh', '0'],
          }}
          transition={{
            delay: default_delay + boost_logo_duration,
            duration: text_duration,
            type: 'spring',
            bounce: 0.2,
          }}
        >
          <MetaText content={meta?.topText?.[0]} />
        </motion.div>
        <motion.div
          animate={{
            translateX: ['150vh', '0'],
          }}
          transition={{
            delay: default_delay + boost_logo_duration,
            duration: text_duration,
            type: 'spring',
            bounce: 0.2,
          }}
        >
          <MetaText content={meta?.bottomText?.[0]} />
        </motion.div>
      </motion.div>
      <motion.div
        style={wheelContainer}
        initial={{ opacity: 0, scale: 0 }}
        animate={{
          opacity: [0, 1],
          scale: [0, 1],
        }}
        transition={{
          opacity: {
            delay: default_delay + boost_logo_duration + text_duration - 0.25,
            duration: wheel_appear_duration,
            ease: 'easeOut',
          },
          scale: {
            delay: default_delay + boost_logo_duration + text_duration - 0.25,
            duration: wheel_appear_duration,
            type: 'spring',
            bounce: 0.3,
          },
        }}
      >
        <BoostWheel boost={meta?.boost} />
      </motion.div>
    </div>
  );
};

interface IBoostImageProps {
  src?: string;
}

const BoostImage = ({ src }: IBoostImageProps): React.ReactElement => {
  return <img src={src} alt="Boost" style={boostImage} />;
};

interface IBoostWheelProps {
  boost?: number;
  boostImage?: string;
  spinDuration?: number;
}

const BoostWheel = ({ boost }: IBoostWheelProps): React.ReactElement => {
  const boostArray = [2, 4, 1, 10, 1.5, 3, 5];
  const selectedIndex = boostArray.indexOf(boost ?? 0);
  const degrees = 360 * 6 - (360 / boostArray.length) * selectedIndex;

  return (
    <div style={wheel}>
      <motion.div
        style={wheelImage}
        initial={{ rotate: 0 }}
        animate={{
          rotate: degrees,
        }}
        transition={{
          delay:
            default_delay +
            boost_logo_duration +
            text_duration +
            wheel_appear_duration,
          duration: spin_duration,
          ease: [0.2, 1.1, 0.7, 1],
        }}
      >
        <BoostWheelSvg />
      </motion.div>
      <motion.img
        src={boostWheelCaret}
        alt="Caret"
        style={wheelCaret}
        initial={{ opacity: 1 }}
        animate={{
          opacity: 0,
        }}
        transition={{
          delay:
            default_delay +
            boost_logo_duration +
            text_duration +
            wheel_appear_duration +
            spin_duration +
            wheel_boost_text_appear_duration +
            wheel_boost_scale_center_duration +
            1 -
            0.25,
          duration: 0.25,
        }}
      />
      <motion.div
        style={wheelCentralContainer}
        initial={{ scale: 1 }}
        animate={{
          scale: 2.3,
        }}
        transition={{
          delay:
            default_delay +
            boost_logo_duration +
            text_duration +
            wheel_appear_duration +
            spin_duration +
            wheel_boost_text_appear_duration +
            1,
          duration: wheel_boost_scale_center_duration,
        }}
      >
        <img
          src={boostWheelBoostText}
          alt="Boost text"
          style={wheelBoostLogo}
        />
        <motion.div
          initial={{ opacity: 0, scale: 0 }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            delay:
              default_delay +
              boost_logo_duration +
              text_duration +
              wheel_appear_duration +
              spin_duration,
            duration: wheel_boost_text_appear_duration,
          }}
        >
          <span style={wheelBoostNumber}>x{boost}</span>
        </motion.div>
      </motion.div>
    </div>
  );
};
