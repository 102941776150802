export const sendMessage = (type: string, data: any) => {
  var originMapper: { [key: string]: string } = {
    'https://extdev.jeux-webretail.loro.ch': 'https://extdev.jeux.loro.ch',
    'https://jint.jeux-webretail.loro.ch': 'https://jint.jeux.loro.ch',
    'https://uat1.jeux-webretail.loro.ch': 'https://uat1.jeux.loro.ch',
    'https://uat2.jeux-webretail.loro.ch': 'https://uat2.jeux.loro.ch',
    'https://preprod.jeux-webretail.loro.ch': 'https://preprod.jeux.loro.ch',
    'https://prod.jeux-webretail.loro.ch': 'https://jeux.loro.ch',
  };

  if (!isRunningInIframe()) {
    return;
  }

  if (originMapper[window.location.origin]) {
    window.parent.postMessage(
      {
        type,
        data,
      },
      originMapper[window.location.origin],
    );
  }
};

const isRunningInIframe = () => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};
