import React, { Suspense, useCallback, useMemo } from 'react';
import { Canvas } from '@react-three/fiber';
import { AnimatePresence, motion } from 'framer-motion';
import { SceneType } from '../../interfaces/sceneState';
import { IStateProps } from '.';
import { SCENE_COMPONENTS, EmptyScene } from './SceneManager';
import { useHandleResize } from './hooks/useHandleResize';
import { useVisibilityChange } from './hooks/useVisibilityChange';
import { canvasAndDivContStyle, canvasStyle, htmlContStyle } from './styles';
import backgroundDefault from './../../assets/images/backgrounds/background_blue_halo.svg';

const FADE_DURATION = 1.7;
const FADE_OUT_DURATION = 1.2;

export const APP_WIDTH = 1280;
export const APP_HEIGHT = 720;
export const screenRatio = APP_HEIGHT / APP_WIDTH;

export const Loex2024Canvas: React.FC<IStateProps> = React.memo((props) => {
  const { scene = SceneType.Loading, backgroundImage = backgroundDefault } =
    props?.currentState || {};

  const { skipAnim, handleExitComplete } = useVisibilityChange(scene);

  const scale = useHandleResize();

  const motionProps = useMemo(
    () => ({
      initial: { opacity: skipAnim.current ? 1 : 0 },
      animate: { opacity: 1 },
      exit: {
        opacity: 0,
        transition: {
          duration: skipAnim.current ? 0 : FADE_OUT_DURATION,
          ease: 'easeInOut',
        },
      },
      transition: { duration: FADE_DURATION, ease: 'easeInOut' },
      style: canvasAndDivContStyle,
    }),
    [skipAnim],
  );

  const canvasProps = useMemo(
    () => ({
      linear: true,
      flat: true,
      style: canvasStyle(backgroundImage),
      gl: {
        antialias: false,
        powerPreference: 'high-performance',
        precision: 'mediump',
      },
      shadows: false,
      dpr: Math.max(window.devicePixelRatio, 1),
    }),
    [backgroundImage],
  );

  const SceneComponent = useMemo(
    () => SCENE_COMPONENTS[scene] || EmptyScene,
    [scene],
  );

  const renderScene = useCallback(
    (isHtml: boolean) => (
      <AnimatePresence onExitComplete={handleExitComplete}>
        <motion.div key={`${scene}-${isHtml ? 'html' : '3d'}`} {...motionProps}>
          {isHtml ? (
            <SceneComponent isHtml={true} {...props} />
          ) : (
            <Canvas {...canvasProps}>
              <ambientLight intensity={4} />
              <Suspense fallback={null}>
                <SceneComponent isHtml={false} {...props} />
              </Suspense>
            </Canvas>
          )}
        </motion.div>
      </AnimatePresence>
    ),
    [
      SceneComponent,
      motionProps,
      canvasProps,
      handleExitComplete,
      props,
      scene,
    ],
  );

  return (
    <>
      <div
        id="canvas-container"
        style={{
          ...canvasAndDivContStyle,
          width: `${APP_WIDTH * scale}px`,
          height: `${APP_HEIGHT * scale}px`,
        }}
      >
        {renderScene(false)}
      </div>
      <div
        id="html-container"
        style={{
          ...htmlContStyle,
          transform: `scale(${scale})`,
        }}
      >
        {renderScene(true)}
      </div>
    </>
  );
});
