import React from 'react';
import { FONTS } from '../../../../enums/fonts';

export const JackpotContainer: React.CSSProperties = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontWeight: 'semibold',
  position: 'absolute',
  display: 'flex',
  alignItems: 'end',
  lineHeight: 1,
  gap: '1em',
  left: '10%',
  top: '22%',
};

export const JackpotSubContainer: React.CSSProperties = {
  paddingBottom: '1em',
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5em',
};

export const gameLogo: React.CSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: 400,
  padding: '25px 40px',
};
