import React from 'react';
import { IStateProps } from '../components/App';

interface SceneProps extends IStateProps {
  isHtml: boolean;
}

export function createScene<T extends IStateProps>(
  HtmlComponent: React.ComponentType<T> | null,
  ThreeDComponent: React.ComponentType<T> | null,
) {
  if (HtmlComponent === null) HtmlComponent = () => <></>;
  if (ThreeDComponent === null) ThreeDComponent = () => <></>;
  return React.memo(function Scene({ isHtml, ...props }: SceneProps) {
    return isHtml ? (
      <HtmlComponent {...(props as T)} />
    ) : (
      <ThreeDComponent {...(props as T)} />
    );
  });
}
