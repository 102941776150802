import React from 'react';
import { motion } from 'framer-motion';
import {
  IBaseMeta,
  SCENES_WITH_RESULTS_BANNER,
  SceneType,
} from '../../../interfaces/sceneState';
import { FONTS } from '../../../enums/fonts';
import { useRescale } from '../../App/hooks/useRescale';
import { getIsChannelTV } from '../../../helpers/getIsChannelTV';
import { IStateProps } from '../../App';
import { getMeta } from '../../../helpers/getMeta';

const drawInProgressContainerStyle = (bottomOffset: boolean) => {
  return {
    position: 'absolute',
    bottom: bottomOffset ? '110px' : '30px',
    left: '44px',
    display: 'flex',
    color: '#FFFFFF',
    textTransform: 'uppercase',
    letterSpacing: '0px',
    flexDirection: 'column',
  } as React.CSSProperties;
};

const outerCircleStyle = {
  width: '18px',
  height: '18px',
  backgroundColor: '#ffffff',
  borderRadius: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '3px',
  marginLeft: '1px',
  marginRight: '3px',
};
const innerCircleStyle = {
  width: '12px',
  height: '12px',
  borderRadius: '50%',
  backgroundColor: '#FF0000',
};

const drawTextContainerStyle = (isSceneInProgress: Boolean) => {
  return {
    fontFamily: FONTS.BarlowCondensedSemiBold,
    fontSize: '25px',
    lineHeight: '24px',
    fontWeight: 'semibold',
    textAlign: 'right',
    display: 'flex',
    flexDirection: isSceneInProgress ? 'row' : 'column',
    justifyContent: 'center',
    marginRight: '10px',
  } as React.CSSProperties;
};
const drawTextAndIconStyle = (isSceneInProgress: Boolean) => {
  return {
    display: 'flex',
    textAlign: 'right',
    justifyContent: isSceneInProgress ? 'flex-start' : 'space-between',
  } as React.CSSProperties;
};
const drawNumberContainerStyle: React.CSSProperties = {
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontSize: '58px',
  lineHeight: '52px',
  fontWeight: 'semibold',
  textAlign: 'left',
};
const drawSplitColorStyle = { color: ' #C1D6FF', marginRight: '1px' };

const AnimatedPulseButton = () => {
  return (
    <motion.div
      animate={{ opacity: [0, 1] }}
      transition={{
        duration: 0.75,
        repeat: 999,
        repeatType: 'reverse',
        ease: 'easeInOut',
      }}
      style={innerCircleStyle}
    />
  );
};

const isSceneDrawInProgress = (scene: SceneType | undefined) => {
  const list = [SceneType.Extra, SceneType.Reorder, SceneType.Draw];
  return list.includes(scene);
};

export function replaceAll(str: string, find: string, replace: string) {
  // Escape special characters in the 'find' string
  const escapedFind = find.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

  // Create a regular expression with the global flag
  const regex = new RegExp(escapedFind, 'g');

  // Use the replace method with the regular expression
  return str.replace(regex, replace);
}

interface IBContent extends IBaseMeta, IStateProps {
  scene?: SceneType;
  withSplash?: boolean;
}

export const DrawInProgress = ({ scene, ...props }: IBContent) => {
  const isChannelTV = getIsChannelTV(props);
  const meta = getMeta(props);
  const stringId = meta?.id?.toString();
  const firstIds = stringId?.slice(0, -3);
  const lastIds = stringId?.slice(-3);
  const isSceneInProgress = isSceneDrawInProgress(scene);
  const currentDrawHeadText = isSceneInProgress
    ? meta?.bottomText?.[0]?.text?.split(' ')?.[0]
    : '';
  const currentDrawTailText = isSceneInProgress
    ? replaceAll(
        meta?.bottomText?.[0]?.text ?? '',
        currentDrawHeadText ?? '',
        '',
      )
    : '';

  const renderDrawText = () => {
    if (isSceneInProgress) {
      return `${currentDrawHeadText ?? ''} `;
    }
    return meta?.lastDrawText?.[0]?.text ?? '';
  };

  const scaleS = useRescale();
  const bottomOffset =
    isChannelTV && SCENES_WITH_RESULTS_BANNER.includes(scene);
  return (
    <div style={{ ...drawInProgressContainerStyle(bottomOffset), ...scaleS }}>
      <div style={drawTextContainerStyle(isSceneInProgress)}>
        <div style={drawTextAndIconStyle(isSceneInProgress)}>
          {isSceneInProgress && firstIds && firstIds?.length > 0 && (
            <span style={outerCircleStyle}>
              <AnimatedPulseButton />
            </span>
          )}
          <span style={{ marginRight: '4px' }}>{renderDrawText()}</span>
        </div>
        <span>{isSceneInProgress ? (currentDrawTailText ?? '') : ''}</span>
      </div>
      <div style={drawNumberContainerStyle}>
        <span style={drawSplitColorStyle}>{firstIds ?? ''}</span>
        <span>{lastIds ?? ''}</span>
      </div>
    </div>
  );
};
