import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { IBangoMeta } from '../../../../../interfaces/sceneState';
import { IStateProps } from '../../..';
import { AnimatedBall } from './AnimatedBall';
import { getMeta } from '../../../../../helpers/getMeta';

const TOTAL_BALLS = 20;
const BALL_TIME_DIFF = 5000;

export const AnimatedBallsMng: React.FC<IStateProps> = React.memo((props) => {
  const meta = getMeta(props) as IBangoMeta;
  const { progress = 0, startTime = 0 } = props.currentState || {};
  const balls = useMemo(() => meta?.balls || [], [meta?.balls]);
  const [activeBalls, setActiveBalls] = useState<number[]>([]);
  const hasBeenLaunched = useRef(false);
  const initialBallsCount = Math.floor((progress - startTime) / BALL_TIME_DIFF);
  const initialBalls = useMemo(
    () => balls.slice(0, Math.min(initialBallsCount, TOTAL_BALLS)),
    [balls, initialBallsCount],
  );

  const startBallInterval = useCallback(() => {
    const interval = setInterval(() => {
      setActiveBalls((prev) => {
        if (prev.length >= TOTAL_BALLS) {
          clearInterval(interval);
          return prev;
        }
        return [...prev, balls[prev.length]].slice(0, TOTAL_BALLS);
      });
    }, BALL_TIME_DIFF);
  }, [balls]);

  useEffect(() => {
    if (hasBeenLaunched.current) return;

    setActiveBalls(initialBalls);
    if (initialBallsCount < TOTAL_BALLS) {
      const remainingTime = BALL_TIME_DIFF - (progress % BALL_TIME_DIFF);

      const timeout = setTimeout(() => {
        setActiveBalls((prev) =>
          [...prev, balls[prev.length]].slice(0, TOTAL_BALLS),
        );
        startBallInterval();
      }, remainingTime);

      hasBeenLaunched.current = true;

      return () => clearTimeout(timeout);
    }
  }, [balls, initialBalls, initialBallsCount, progress, startBallInterval]);

  return (
    <>
      {balls.map((ballNumber, index) => (
        <AnimatedBall
          key={index}
          number={ballNumber}
          triggerAnimation={activeBalls.includes(ballNumber)}
          initialBall={initialBalls.includes(ballNumber)}
        />
      ))}
    </>
  );
});
