import { motion } from 'framer-motion';
import extra from '../../../assets/extra.svg';

const contentContainer: React.CSSProperties = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
};

const extraImageContainer: React.CSSProperties = {
  position: 'absolute',
  display: 'flex',
  flex: 1,
};

const default_delay = 0;
const extra_logo_duration = 2.5;

export const ExtraAnimated = ({ extraImage }: { extraImage: string }) => {
  return (
    <div style={contentContainer}>
      <motion.div
        style={extraImageContainer}
        initial={{ opacity: 0, scale: 0.001 }}
        animate={{
          opacity: [0, 1, 1, 1, 0],
          scale: [0.001, 1],
        }}
        transition={{
          opacity: {
            delay: default_delay,
            duration: extra_logo_duration,
            ease: 'easeInOut',
          },
          scale: {
            delay: default_delay,
            duration: extra_logo_duration,
            type: 'spring',
            bounce: 0.3,
          },
        }}
      >
        <ExtraImage src={extraImage ?? extra} />
      </motion.div>
    </div>
  );
};

export const extraImage: React.CSSProperties = {
  width: 433,
};

interface IExtraImageProps {
  src?: string;
}

const ExtraImage = ({ src }: IExtraImageProps): React.ReactElement => {
  return <img src={src} alt="Boost" style={extraImage} />;
};
