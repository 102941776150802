import React, { useCallback, useEffect, useState } from 'react';
import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import moment from 'moment-timezone';
import { motion } from 'framer-motion';
import {
  backgroundImage,
  box,
  contentContainer,
  countdownContainer,
  separator,
  textLineContainer,
} from './styles';
import { MetaText } from '../../../Common/MetaText';
import { COMBINED_FONT } from '../../../../enums/fonts';
import { createScene } from '../../../../helpers/createScene';
import { getMeta } from '../../../../helpers/getMeta';

const timezone = 'Europe/Zurich';
const countdownConfig = {
  fontFamily: COMBINED_FONT.BarlowCondensedOrDefault,
  fontSize: 64,
  backgroundColor: '#fff',
};

const default_delay = 0.5;

interface IBContent extends IBaseMeta {
  textLines?: Texts;
  backgroundImages?: string[];
}

const NightTimeModeHTML = (props: IStateProps): React.ReactElement => {
  const meta = getMeta(props);
  return (
    <>
      <BackgroundImages {...meta} />
      <Contents {...meta} />
      <LotoExpressLogo {...props} />
    </>
  );
};

export const NightTimeModeScene = createScene(NightTimeModeHTML, null);

const BackgroundImages = ({ ...meta }: IBContent) => {
  return (
    <>
      {meta?.backgroundImages?.map((src, i) => (
        <img
          src={src}
          style={backgroundImage}
          alt={`background-${i}`}
          key={i}
        />
      ))}
    </>
  );
};

const Contents = ({ ...meta }: IBContent) => {
  const now = moment.tz(timezone);

  // // the sales open at 6am, but the 1st draw is at 6:05am
  const openTimeForToday = moment
    .tz(timezone)
    .startOf('day')
    .hour(6)
    .minutes(5);
  // the sales close at 11pm, but the draw of 11pm is visible so close time is actually 11:05pm
  const closeTimeForToday = moment
    .tz(timezone)
    .startOf('day')
    .hour(23)
    .minutes(5);
  // in case we are before midnight, we display the countdown until tomorrow morning
  const openTimeForTomorrow = moment
    .tz(timezone)
    .add(1, 'day')
    .startOf('day')
    .hour(6)
    .minutes(5);

  let nextOpenTime: moment.Moment | undefined = undefined;
  if (now <= openTimeForToday) {
    nextOpenTime = openTimeForToday;
  } else if (now >= closeTimeForToday) {
    nextOpenTime = openTimeForTomorrow;
  }

  return (
    <div style={contentContainer}>
      <TextLines texts={meta.textLines ?? []} />
      <CountDown
        date={nextOpenTime}
        tz={timezone}
        animation={{ delay: (meta.textLines?.length ?? 0) * 0.25 }}
      />
    </div>
  );
};

interface ITextsProps {
  texts: Texts;
}

const TextLines = ({ texts }: ITextsProps) => {
  return (
    <div style={textLineContainer}>
      {texts.map((t, i) => (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            delay: default_delay + i * 0.25,
            duration: 0.5,
          }}
          key={i}
        >
          <MetaText content={t} />
        </motion.div>
      ))}
    </div>
  );
};

interface ICountdown {
  date: moment.Moment | undefined;
  tz?: string;
  animation?: { delay: number };
}

const CountDown = ({ date, tz = timezone, animation }: ICountdown) => {
  const [values, setValues] = useState<string[][]>([]);
  const extractValues = useCallback((): string[][] => {
    if (!date) {
      return [
        ['0', '0'],
        ['0', '0'],
      ];
    }

    const now = moment().tz(tz);
    const format = date.diff(now, 'hours') <= 0 ? 'mm:ss' : 'HH:mm:ss';

    return moment
      .utc(moment.duration(date.diff(now)).asMilliseconds())
      .format(format)
      .split(':')
      .map((v) => v.split(''));
  }, [date, tz]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const extractedValues = extractValues();
      setValues(extractedValues);
    }, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, [extractValues]);

  return (
    <motion.div
      style={{ minHeight: 100 }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        delay: default_delay + (animation?.delay ?? 0),
        duration: 0.5,
      }}
    >
      <div style={countdownContainer}>
        {values.map((v, vi) => (
          <>
            {v.map((b, bi) => (
              <Box
                key={`${vi}-${bi}`}
                backgroundColor={countdownConfig.backgroundColor}
                fontFamily={countdownConfig.fontFamily}
                fontSize={countdownConfig.fontSize}
              >
                {b}
              </Box>
            ))}
            {vi < values.length - 1 ? (
              <Separator
                color={countdownConfig.backgroundColor}
                fontFamily={countdownConfig.fontFamily}
                fontSize={countdownConfig.fontSize}
                key={`${vi}-${3}`}
              >
                :
              </Separator>
            ) : (
              <></>
            )}
          </>
        ))}
      </div>
    </motion.div>
  );
};

interface IBoxProps {
  children?: React.ReactNode;
  fontFamily?: string;
  fontSize?: number;
  backgroundColor?: string;
}

const Box = ({
  backgroundColor,
  fontSize,
  fontFamily,
  children,
}: IBoxProps) => {
  return (
    <span
      style={{
        ...box,
        backgroundColor: backgroundColor,
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {children}
    </span>
  );
};

interface ISeparatorProps {
  children?: React.ReactNode;
  fontFamily?: string;
  fontSize?: number;
  color?: string;
}

const Separator = ({
  color,
  fontSize,
  fontFamily,
  children,
}: ISeparatorProps) => {
  return (
    <span
      style={{
        ...separator,
        color: color,
        fontFamily: fontFamily,
        fontSize: fontSize,
      }}
    >
      {children}
    </span>
  );
};
