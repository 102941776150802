import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';
import { motion } from 'framer-motion-3d';
import { Sparkles } from '@react-three/drei';
import { BallFullYellow, BallWithNumber } from '../ball';
import RevealEffect from '../shaders/RevealEffect';
import { T3D } from '..';
import { ballNumberToGridPosition } from '../helpers/ballNumberToGridPosition';
import { getRandomBorderPosition } from '../helpers/getRandomBorderPosition';
import { useAnimation } from 'framer-motion';
import { FakeGlowMaterial } from '../shaders/FakeGlowMaterial';
import * as THREE from 'three';
import { useFrame } from '@react-three/fiber';
import { createInstancedBillboardMaterial } from '../shaders/instancedBillboardMaterial';

const CONSTANTS = {
  INITIAL_BALL_OFFSET: -19,
  BASE_Z_OFFSET: 0.5,
  MIDDLE_Z_OFFSET: 2,
  RANDOM_OFFSET_RANGE: 0,
  RANDOM_OFFSET_CENTER: 0,
  INITIAL_ANIMATION_DURATION: 1.4,
  FINAL_ANIMATION_DURATION: 0.9,
  REVEAL_DURATION: 1500,
  NUMBER_REVEAL_DELAY: 750,
  FINAL_Z_OFFSET: 0.0007,
  TRAIL_WIDTH: 11,
  TRAIL_ATTENUATION: 0.6,
  SPARKLES_COUNT: 3,
  SPARKLES_SIZE: 3,
  SPARKLES_SCALE: 0.5,
  GLOW_SPHERE_RADIUS: 2,
  GLOW_SPHERE_SEGMENTS: 3,
  GLOW_SPHERE_H_SEGMENTS: 2,
};

const PHASES = {
  DEFAULT: -1,
  GO_TO_CENTER: 0,
  GO_TO_GRID_POS: 2,
  REVEAL: 3,
  FINAL: 4,
  END: 5,
};

interface BallProps {
  number: number;
  triggerAnimation: boolean;
  initialBall: boolean;
}

export const AnimatedBall: React.FC<BallProps> = React.memo(
  ({ number, triggerAnimation, initialBall }) => {
    const controls = useAnimation();
    const [currentState, setCurrentState] = useState(PHASES.DEFAULT);

    const initialBallOffset = initialBall ? CONSTANTS.INITIAL_BALL_OFFSET : 0;
    const zBase = CONSTANTS.BASE_Z_OFFSET + initialBallOffset;
    const zMiddle = zBase + CONSTANTS.MIDDLE_Z_OFFSET;
    const finalPosition = useMemo(
      () => ballNumberToGridPosition(number),
      [number],
    );
    const borderPosition = useMemo(() => getRandomBorderPosition(), []);
    const randomOffset = useMemo(
      () => [
        Math.random() * CONSTANTS.RANDOM_OFFSET_RANGE -
          CONSTANTS.RANDOM_OFFSET_CENTER,
        Math.random() * CONSTANTS.RANDOM_OFFSET_RANGE -
          CONSTANTS.RANDOM_OFFSET_CENTER +
          initialBallOffset,
      ],
      [initialBallOffset],
    );

    const animateBall = useCallback(async () => {
      const elapsedTime =
        Date.now() / 1000 + CONSTANTS.INITIAL_ANIMATION_DURATION;
      const skipAnimation = async () => {
        const totalAnimationTime =
          CONSTANTS.INITIAL_ANIMATION_DURATION +
          CONSTANTS.FINAL_ANIMATION_DURATION;
        const skip = Date.now() / 1000 - elapsedTime > totalAnimationTime;
        if (skip) {
          setCurrentState(PHASES.FINAL);
          controls.set({
            x: finalPosition[0],
            y: finalPosition[1],
            z: finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
          });
          controls.stop();
        }
        return skip;
      };

      if (!initialBall) {
        if (await skipAnimation()) return;
        setCurrentState(PHASES.GO_TO_CENTER);
        await controls
          .start({
            x: randomOffset[0],
            y: randomOffset[1],
            z: zMiddle,
            transition: {
              duration: CONSTANTS.INITIAL_ANIMATION_DURATION,
              ease: 'easeInOut',
            },
          })
          .catch((e) => {
            controls.stop();
          });
      }

      if (await skipAnimation()) return;
      setCurrentState(PHASES.GO_TO_GRID_POS);
      await controls
        .start({
          x: finalPosition[0],
          y: finalPosition[1],
          z: finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
          transition: {
            ease: 'easeInOut',
            duration: initialBall
              ? CONSTANTS.INITIAL_ANIMATION_DURATION
              : CONSTANTS.FINAL_ANIMATION_DURATION,
          },
        })
        .catch(() => {
          controls.stop();
        });
      if (await skipAnimation()) return;
      await new Promise((resolve) => setTimeout(resolve, 350));
      setCurrentState(PHASES.REVEAL);

      await new Promise((resolve) =>
        setTimeout(resolve, CONSTANTS.REVEAL_DURATION),
      );
      if (await skipAnimation()) return;

      setCurrentState(PHASES.FINAL);
    }, [controls, randomOffset, finalPosition, zMiddle, initialBall]);

    useEffect(() => {
      if (triggerAnimation) {
        animateBall();
      }
    }, [triggerAnimation, animateBall]);

    useEffect(() => {
      return () => {
        setCurrentState(PHASES.END);
        controls.stop();
      };
    }, [controls]);

    const showReveal = currentState >= PHASES.REVEAL;

    const showTrailAndBall =
      (currentState === PHASES.GO_TO_GRID_POS || !initialBall) &&
      currentState <= PHASES.GO_TO_GRID_POS &&
      currentState > PHASES.DEFAULT;

    const showTrail =
      currentState < PHASES.REVEAL &&
      ((currentState >= PHASES.GO_TO_CENTER && !initialBall) ||
        currentState >= PHASES.GO_TO_GRID_POS);

    const initialPosition = [
      initialBall ? randomOffset[0] : borderPosition[0],
      initialBall ? randomOffset[1] : borderPosition[1],
      initialBall ? zMiddle : zBase,
    ] as T3D;
    const ballRef = useRef(null!);

    return (
      <>
        {showTrail && (
          <Trail followObject={ballRef} initialBall={initialBall} />
        )}

        <motion.group
          ref={ballRef}
          position={initialPosition}
          initial={false}
          animate={controls}
          visible={triggerAnimation}
        >
          <BallAndTrail
            showTrailAndBall={showTrailAndBall}
            initialBall={initialBall}
          />
        </motion.group>

        {showReveal && finalPosBall(finalPosition, triggerAnimation, number)}

        {(currentState === PHASES.REVEAL || currentState === PHASES.FINAL) &&
          revealEffects(finalPosition, currentState, initialBall)}
      </>
    );
  },
);

const Trail = ({
  followObject,
  initialBall,
}: {
  followObject: React.RefObject<THREE.Object3D>;
  initialBall: boolean;
}) => {
  const count = 10;
  const followingObjPosition = useMemo(
    () => followObject.current?.position ?? new THREE.Vector3(0, 0, 0),
    [followObject],
  );
  const meshRef = useRef<THREE.InstancedMesh>(null);
  const dummy = useMemo(() => new THREE.Object3D(), []);

  const trailPositions = useRef(
    new Array(count).fill(null).map(() => new THREE.Vector3()),
  );

  useEffect(() => {
    const GlobalOffset = 10;
    const stepOffset = 1.5;
    trailPositions.current.forEach((pos, i) => {
      pos.set(
        followingObjPosition?.x,
        followingObjPosition?.y,
        followingObjPosition?.z + GlobalOffset + i * stepOffset,
      );
    });
  }, [followingObjPosition]);

  const delayFactor = initialBall ? 0.5 : 0.42; // Adjust this to control how far behind the trail follows
  const scaleFactor = 0.84; // Adjust this to control how quickly the trail scales down

  useFrame((state) => {
    if (!followObject.current || !meshRef.current) return;
    trailPositions.current[0].copy(followingObjPosition);

    // Update trail positions with delay
    for (let i = trailPositions.current.length - 1; i > 0; i--) {
      trailPositions.current[i].lerp(
        trailPositions.current[i - 1],
        delayFactor,
      );
    }

    // Update instance matrices
    for (let i = 0; i < count; i++) {
      const scale = Math.pow(scaleFactor, i);
      dummy.position.copy(trailPositions.current[i]);
      dummy.position.z += 0.01;

      const isSame =
        Math.abs(trailPositions.current[i].x - followingObjPosition.x) < 0.01 &&
        Math.abs(trailPositions.current[i].y - followingObjPosition.y) < 0.01 &&
        Math.abs(trailPositions.current[i].z - followingObjPosition.z) < 0.01;
      if (i === count - 1 && isSame) {
        meshRef.current.visible = false;
      }
      if (false || i <= 0) {
        dummy.scale.set(0, 0, 0);
      } else {
        dummy.scale.set(scale, scale, scale);
      }
      dummy.updateMatrix();
      meshRef.current.setMatrixAt(i, dummy.matrix);
    }

    meshRef.current.instanceMatrix.needsUpdate = true;
  });

  const standarBillboardMaterial = useMemo(() => {
    return createInstancedBillboardMaterial(
      new THREE.MeshBasicMaterial({
        color: '#fffffa',
        side: THREE.BackSide,
        transparent: true,
        opacity: 0.93,
      }),
    );
  }, []);
  return (
    <instancedMesh
      ref={meshRef}
      args={[undefined, standarBillboardMaterial, count]}
      renderOrder={0}
    >
      <ringGeometry args={[0.29, 0.24, 18, 1]} />
    </instancedMesh>
  );
};

const BallAndTrail = ({
  showTrailAndBall,
  initialBall,
}: {
  showTrailAndBall: boolean;
  initialBall: boolean;
}) => {
  const ballRef = useRef(null!);

  if (!showTrailAndBall) return null;

  return <BallFullYellow front={!initialBall} meshRef={ballRef} />;
};
function finalPosBall(
  finalPosition: T3D,
  triggerAnimation: boolean,
  number: number,
) {
  return (
    <group
      position={[
        finalPosition[0],
        finalPosition[1],
        finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
      ]}
      visible={triggerAnimation}
    >
      <BallWithNumber number={number} transition={true} staticPos={true} />
    </group>
  );
}

function revealEffects(
  finalPosition: T3D,
  currentState: number,
  initialBall: boolean,
) {
  return (
    <group
      position={[
        finalPosition[0],
        finalPosition[1],
        finalPosition[2] + CONSTANTS.FINAL_Z_OFFSET,
      ]}
    >
      {currentState === PHASES.REVEAL && !initialBall && <RevealEffect />}
      <mesh>
        <sphereGeometry
          args={[
            CONSTANTS.GLOW_SPHERE_RADIUS,
            CONSTANTS.GLOW_SPHERE_SEGMENTS,
            CONSTANTS.GLOW_SPHERE_H_SEGMENTS,
          ]}
        />
        <FakeGlowMaterial
          glowColor={'#f0f011'}
          glowSharpness={0.1}
          glowInternalRadius={8}
          falloff={1}
        />
      </mesh>
      <Sparkles
        color={'#ffff33'}
        count={CONSTANTS.SPARKLES_COUNT}
        size={CONSTANTS.SPARKLES_SIZE}
        scale={CONSTANTS.SPARKLES_SCALE}
        position={[0, 0, 0.2]}
      />
    </group>
  );
}
