import { IStateProps } from '../..';
import { DrawInProgress } from '../../../Common/DrawInProgress';
import { NextDraw } from '../../../Common/NextDraw';
import { BoostSplash } from '../../../Common/BoostSplash';
import { LotoExpressLogo } from '../../../Common/LotoExpressLogo';
import {
  IBangoMeta,
  IBaseMeta,
  Texts,
} from '../../../../interfaces/sceneState';
import { MetaText } from '../../../Common/MetaText';
import { FONTS } from '../../../../enums/fonts';
import { motion } from 'framer-motion';
import {
  HappyWinnersContentStyle,
  HappyWinnersContentWinAmountStyle,
  HappyWinnersStyle,
  HappyWinnersValueStyle,
} from './styles';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';
import ResultBanner from '../../../Common/ResultBanner';

interface IBContent extends IBaseMeta {
  happyWinners?: number;
  middleText?: Texts;
  topText?: Texts;
  winAmount?: number;
  alternativeText?: Texts;
}

const HappyWinnersHTML = (props: IStateProps) => {
  const meta = getMeta(props) as IBangoMeta;

  const formatCHF = (amount: number) => {
    const formattedAmount = amount.toLocaleString('en-US');
    return formattedAmount.replace(/,/g, "'") + '.-';
  };
  const HappyWinnersContent = ({ ...meta }: IBContent) => {
    const withWinAmount: boolean = meta.winAmount && meta.winAmount > 1000;
    return (
      <div style={HappyWinnersStyle(withWinAmount)}>
        {withWinAmount ? (
          <>
            <motion.div
              style={{
                ...HappyWinnersContentWinAmountStyle(20),
                ...HappyWinnersValueStyle(true),
              }}
              animate={{ opacity: [1, 0.3, 1] }}
              transition={{
                duration: 3,
                repeat: 999,
                repeatType: 'loop',
                ease: 'easeIn',
              }}
            >
              {formatCHF(meta?.winAmount ?? 0)}
            </motion.div>
            <div style={HappyWinnersContentWinAmountStyle(40)}>
              <MetaText
                customFont={FONTS.BarlowCondensedBold}
                content={meta?.alternativeText?.[0]}
              />
            </div>
          </>
        ) : (
          <>
            <motion.div
              style={HappyWinnersValueStyle(false)}
              animate={{ opacity: [1, 0.3, 1] }}
              transition={{
                duration: 3,
                repeat: 999,
                repeatType: 'loop',
                ease: 'easeIn',
              }}
            >
              {meta?.happyWinners}
            </motion.div>
            <div style={HappyWinnersContentStyle}>
              <MetaText
                customFont={FONTS.BarlowCondensedBold}
                content={meta?.topText?.[0]}
              />
              <MetaText
                customFont={FONTS.BarlowCondensedBold}
                content={meta?.middleText?.[0]}
              />
              <MetaText
                customFont={FONTS.BarlowCondensedBold}
                content={meta?.bottomText?.[0]}
              />
            </div>
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <LotoExpressLogo {...props} />
      <DrawInProgress scene={props.displayState} {...props} />
      <NextDraw {...meta} />
      <BoostSplash scene={props.displayState} {...props} />
      <HappyWinnersContent {...meta} />
      <ResultBanner
        ballValues={meta.balls}
        extraValue={meta.extra}
        {...props}
      />
    </>
  );
};

export const HappyWinnersScene = createScene(HappyWinnersHTML, null);
