import { motion } from 'framer-motion';
import { useEffect, useRef } from 'react';
import { countDownContainer, sizeContainer } from './styles';
import { createScene } from '../../../../helpers/createScene';
import { FONTS } from '../../../../enums/fonts';

const CountdownHTML = () => {
  const countRef = useRef(5);
  const textRef = useRef<SVGTextElement>(null);

  useEffect(() => {
    if (textRef.current) {
      textRef.current.textContent = countRef.current.toString();
    }
    const interval = setInterval(() => {
      countRef.current -= 1;
      if (textRef.current) {
        textRef.current.textContent = countRef.current.toString();
      }
      if (countRef.current === 1) {
        clearInterval(interval);
      }
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  const cicleValues = [295, 0];
  const nbCicles = 5;

  const times = Array(nbCicles)
    .fill(0)
    .map((_, i) => [(1 / nbCicles) * i, (1 / 5) * (i + 1)])
    .flat();

  const values = Array(nbCicles).fill(cicleValues).flat();

  return (
    <motion.div
      style={countDownContainer}
      initial={{ opacity: 1 }}
      animate={{ opacity: 0 }}
      transition={{ delay: nbCicles, duration: 1, ease: 'easeOut' }}
    >
      <div style={sizeContainer}>
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="50"
            cy="50"
            r="46"
            strokeWidth="4"
            stroke="white"
            fill="none"
            opacity="0.8"
          />
          <circle cx="50" cy="50" r="25" fill="#FC5151" />
          <motion.text
            ref={textRef}
            x="49.5%"
            y="52%"
            fill="white"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{
              fontSize: '30px',
              fontWeight: 'semibold',
              fontFamily: FONTS.BarlowCondensedSemiBold,
            }}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
          >
            5
          </motion.text>
          <motion.circle
            cx="50"
            cy="50"
            r="46"
            strokeWidth="1.5"
            fill="none"
            stroke="#FC5151"
            strokeDasharray="295"
            strokeDashoffset="295"
            strokeLinecap="round"
            transform="rotate(-90 50 50)"
            initial={{ strokeDashoffset: 295 }}
            animate={{
              strokeDashoffset: values,
            }}
            transition={{
              duration: 5,
              ease: 'linear',
              times: times,
            }}
          />
        </svg>
      </div>
    </motion.div>
  );
};

export const CountdownScene = createScene(CountdownHTML, null);
