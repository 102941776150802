import { motion } from 'framer-motion';
import { IStateProps } from '../..';
import { IBaseMeta, Texts } from '../../../../interfaces/sceneState';
import { container } from './styles';
import { getMeta } from '../../../../helpers/getMeta';
import { createScene } from '../../../../helpers/createScene';

const default_delay = 1.5;
const logo_appear_duration = 1.5;

interface IBContent extends IBaseMeta {
  logoImage?: string;
  topText?: Texts;
  bottomText?: Texts;
  sceneDuration: number;
}

const LogoHTML = (props: IStateProps): React.ReactElement => {
  const meta = getMeta(props);
  return <Container sceneDuration={props.currentState.duration} {...meta} />;
};

export const LogoScene = createScene(LogoHTML, null);

const Container = ({ ...meta }: IBContent): React.ReactElement => {
  return (
    <div style={container}>
      <Logo logo={meta.logoImage} />
    </div>
  );
};

interface ILogoProps {
  logo: string;
}

const Logo = ({ logo }: ILogoProps): React.ReactElement => {
  const scaleInit = 1;
  const scaleUp = 77;
  const width = 335;
  const height = 105;
  const initWidth = width * scaleInit;
  const initHeight = height * scaleInit;
  const finalWidth = width * scaleUp;
  const finalHeight = height * scaleUp;
  return (
    <motion.img
      width={initWidth}
      height={initHeight}
      src={logo}
      initial={{
        width: initWidth,
        height: initHeight,
        y: 0,
        x: 0,
      }}
      animate={{
        width: finalWidth,
        height: finalHeight,
        y: -1400,
        x: 160,
      }}
      transition={{
        delay: default_delay,
        duration: logo_appear_duration,
        ease: 'easeInOut',
      }}
      alt="Loto Express logo"
    />
  );
};
