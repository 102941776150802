import { motion, MotionProps } from 'framer-motion';
import { background } from './styles';
import backgroundBoostTen from '../../../assets/images/backgrounds/background_boost_ten.svg';

interface IBoostBackground extends MotionProps {
  boost?: number;
}

const BoostBackground = ({
  boost,
  ...props
}: IBoostBackground): React.ReactElement => {
  if (!boost || boost !== 10) {
    return <></>;
  }

  return (
    <motion.div
      {...props}
      style={{
        ...background,
        ...props.style,
        backgroundImage: `url(${backgroundBoostTen})`,
      }}
    />
  );
};

export default BoostBackground;
