import { COLORS } from '../../../constants/colours';
import { FONTS } from '../../../enums/fonts';

export const bannerStyle: React.CSSProperties = {
  position: 'absolute',
  backgroundColor: COLORS.yellow2024,
  alignItems: 'center',
  display: 'flex',
  width: '100%',
  height: 88,
  bottom: 0,
};

export const ballsStyle: React.CSSProperties = {
  display: 'flex',
  padding: '0 16px',
  width: '100%',
  justifyContent: 'space-between',
};

export const ballStyle: React.CSSProperties = {
  borderRadius: '50%',
  height: 54,
  width: 54,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontFamily: FONTS.BarlowCondensedSemiBold,
  fontSize: 36,
  fontWeight: 'bold',
  backgroundColor: COLORS.white,
  color: COLORS.black,
};
