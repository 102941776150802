import moment from 'moment';
import * as React from 'react';
import { connect } from 'react-redux';
import { saveCurrentState } from '../../actions/scene';
import { networkErrorScene } from '../../constants/scene';
import { UIState } from '../../enums/app';
import { getClassNames } from '../../helpers/styles';
import { ISettings, type IAppState, type IUIState } from '../../interfaces/app';
import { SceneType, type SceneState } from '../../interfaces/sceneState';
import Connector from '../../services/signalr-connection';
import ComparingComponent from '../Common/ComparingComponent';
import styles from './styles';
import { withConfigurationPreloader } from '../../helpers/withConfigurationPreloader';
import { Loex2024Canvas } from './Loex2024Canvas';
import WebGL from '../WebGL';
import { extractSceneFeatureFlag } from '../../helpers/featureFlag';
import { FeatureFlag } from '../../enums/featureFlag';
import { sendMessage } from '../../helpers/message';
import { Dispatch, UnknownAction } from 'redux';

export interface IStateProps {
  currentState?: SceneState<{}>;
  displayState: SceneType;
  uiState: IUIState;
  debug: boolean;
  language: string;
  loop?: string;
  settings: ISettings;
}

export interface IDispatchProps {
  dispatch: Dispatch;
}

export class App extends ComparingComponent<IStateProps & IDispatchProps> {
  private readonly delayNetworkErrorMs = 60000;

  constructor(props: IStateProps & IDispatchProps) {
    super(props);
    const { dispatch } = this.props;
    const connector = Connector(props.loop || '', props.debug);

    connector.events((currentScene: SceneState) => {
      sendMessage('scene', currentScene);
      dispatch(
        saveCurrentState(currentScene, {
          readyState: UIState.Ready,
          lastUpdate: new Date(),
        }) as unknown as UnknownAction,
      );
    });

    connector.onError = () => {
      dispatch(
        saveCurrentState(networkErrorScene, {
          readyState: UIState.Ready,
          lastUpdate: new Date(),
        }) as unknown as UnknownAction,
      );
    };

    connector.onRetry = (elapsedMilliseconds: number) => {
      if (elapsedMilliseconds >= this.delayNetworkErrorMs) {
        dispatch(
          saveCurrentState(networkErrorScene, {
            readyState: UIState.Ready,
            lastUpdate: new Date(),
          }) as unknown as UnknownAction,
        );
      }
    };
  }

  public componentDidMount(): void {
    // NOTE: This clear the timeout which will reload the page if it never reaches here
    clearTimeout((window as any).reloadTimeout);
    setTimeout(() => window.scrollTo(0, 0), 500);
  }

  render(): React.ReactNode {
    const {
      props: {
        displayState,
        debug,
        uiState: { lastUpdate, loadTimestamp },
      },
    } = this;

    const meta = this.props.currentState?.meta;
    const classNames = getClassNames(styles);

    const sceneFlag = extractSceneFeatureFlag(
      this.props.settings.featureFlags || [],
    );
    const loex2024Scene = sceneFlag === FeatureFlag.Loex2024;
    return (
      <>
        <WebGL />
        {loex2024Scene && <Loex2024Canvas {...this.props} />}
        {debug ? (
          <>
            <div className={classNames.container}>
              <p className={classNames.text}>
                <span>Host:</span>
                <b style={{ fontWeight: 'bold' }}>{window.location.href}</b>
              </p>
              <p className={classNames.text}>
                <span>Loaded (Client):</span>
                <b style={{ fontWeight: 'bold' }}>
                  {moment(loadTimestamp).format()}
                </b>
              </p>
              <hr />
              <p className={classNames.text}>
                <span>Last Updated (Client):</span>
                <b style={{ fontWeight: 'bold' }}>
                  {moment(lastUpdate).format()}
                </b>
              </p>
              <hr />
              <p className={classNames.text}>
                <span>Current Display State:</span>
                <b style={{ fontWeight: 'bold' }}>{displayState}</b>
              </p>

              <p className={classNames.text}>
                <span>Current Display State Content:</span>
                {meta && <span>{JSON.stringify(meta)}</span>}
              </p>
              <hr />
            </div>
          </>
        ) : null}
      </>
    );
  }
}
export default connect<IStateProps, {}, {}, IAppState>(
  (state: IAppState): IStateProps => ({
    currentState: state.currentState,
    displayState: state.currentState?.scene
      ? state.currentState.scene
      : SceneType.Unknown,
    uiState: state.uiState,
    debug: state.settings.debug,
    language: state.language,
    loop: state.settings.loop,
    settings: state.settings,
  }),
)(withConfigurationPreloader(App));
